<template>
  <div class="workflows">
    <WorkflowInfo :workflowId="this.workflowId" :projectName="this.projectName"/>
    <WorkflowSteps :workflowId="this.workflowId" :projectName="this.projectName"/>
  </div>
</template>

<script>
import WorkflowSteps from '../components/WorkflowDetails/WorkflowSteps.vue'
import WorkflowInfo from '../components/WorkflowDetails/WorkflowInfo.vue'

export default {
  name: 'Workflow',
  components: {
    WorkflowInfo,
    WorkflowSteps,
  },
  props: {
    workflowId: String,
    projectName: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#workflow-table {
  width: 90%;
  margin:1rem auto;
}

table, th, td {
  border:1px solid black;
  line-height: 2;
}

tr:nth-child(even) {
  background-color: lightgray;
  }

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

</style>
