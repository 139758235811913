<template>
  <div class="workflows">
    <Notification/>
    <h1>Workflow Details</h1>
    <table id="workflow-table" v-if="!hide">
      <thead>
        <tr>
          <th>Project</th>
          <th>Name</th>
          <th>Description</th>
          <th>Configuration File</th>
          <th>ID</th>
          <th>Status</th>
          <th v-if="last_update !== undefined">Last Update</th>
          <th>Queue</th>
          <th v-if="this.workflowActions!==undefined">Commands</th>
        </tr>
      </thead>
      <font-awesome-icon v-show="workflowLoading" :icon="['fas', 'spinner']" fa-spin class="fa-3x loading-component"/>
      <tbody v-show="!workflowLoading">        
        <tr>
            <td>{{ workflow.project}}</td>
            <td>{{ workflow.name}}</td>
            <td>{{workflow.description}}</td>
            <td v-if="workflow.url!==undefined"><a :href="'https://s3.console.aws.amazon.com/s3/object/' + workflow.url.split('s3://')[1].split('/')[0] + '?region=eu-west-1&prefix=workflows/' + workflow.url.split('s3://')[1].split('/').slice(Math.max(workflow.url.split('s3://')[1].split('/').length - 1))" target="_blank">Workflow File</a></td>
            <td>{{ this.workflowId}}</td>
            <td :class='{error: !(workflow.error_message === undefined || workflow.error_message ==="")}'>{{ workflow.error_message === undefined || workflow.error_message === ""? workflow.status: "Workflow In Error: " + workflow.error_message}}</td>
            <td v-if="last_update !== undefined">{{ showDate(workflow.last_update)}}</td>
            <td><SelectList style="width:90%" :showClear="false" :items="queues.filter(q => q.project === workflow.project).map(q => q.name)" :message="'Queue'" :selectedModel="workflow.queue" @filterList="handleSelectQueue" /></td>
            <td v-if="this.workflowActions!==undefined">  
              <select style="width:90%"
                id="workflow-action-filter"
                name="workflow-action-filter"
                class="form-control mt-1"
                v-model="selectedWorkflowAction"
                @change="selectWorkflowAction">
              <option value="" disabled selected>Choose a command</option>
              <option v-for="wa in workflowActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
              </select>
              <button v-show="selectedWorkflowAction !== undefined && selectedQueue!==undefined" type="button" class="app-button primary-button" v-on:click="updateWorkflowStatusAndRefresh">Go</button>
            </td>
        </tr>
        <td><router-link target="_blank" :to="{ name: 'WorkflowJobsSearch', params: {projectName: this.projectName, workflowId: this.workflowId }}">Search Workflow Jobs</router-link></td>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Notification from '../Generics/Notification.vue'
import SelectList from '../Generics/SelectList.vue'
import Collapser from '../Generics/Collapser.vue'
import displayDate from "../../utils/dateHandler.js"

export default {
  name: 'WorkflowInfo',
  components: {
    Notification,
    SelectList,
    Collapser
  },
  props: {
    workflowId: String,
    projectName: String,
    selectedWorkflowAction: String
  },
  computed: {
    ...mapGetters({
      workflow: 'getWorkflow',
      workflowLoading: 'workflowLoading',
      workflowActions: 'getWorkflowActions',
      queues: 'getQueues',
      selectedQueue: 'getSelectedQueue'
    })
  },
  data(){
    return{
      hide: false
    }
  },
  methods: {
    ...mapActions([
      'loadWorkflow', 
      'loadWorkflowActions', 
      'selectWorkflowAction', 
      'updateWorkflowStatus',
      'selectWorkflowQueue',
      'loadWorkflowSteps',
      'loadWorkflowStepsStatus',
      'loadWorkflowStepActions',
      'loadQueues'
    ]),
    async updateWorkflowStatusAndRefresh(){
      // run workflow command and reload workflow info
      await this.updateWorkflowStatus(this.workflow);
      let args = {workflowId: this.workflowId, projectName: this.projectName}
      await this.loadWorkflow(args)
      await this.loadWorkflowActions(this.workflow.status)
      // reload the step status table details
      await this.loadWorkflowSteps(args)
      await this.loadWorkflowStepsStatus(args)
      await this.loadWorkflowStepActions()
    },
    handleSelectQueue(selectedQueue){
      this.selectWorkflowQueue(selectedQueue)
    },
    toggleElement(){
      this.hide = !this.hide
    },
    showDate(date){
      return displayDate(date)
    }
  },
  async mounted () {
    await this.loadWorkflow({workflowId: this.workflowId, projectName: this.projectName})
    await this.loadWorkflowActions(this.workflow.status)
    await this.loadQueues()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#workflow-table {
  width: 90%;
  margin: 1rem auto;
  table-layout: fixed;
  word-wrap: break-word;
  box-shadow: 0.05rem 0.05rem 0.05rem;
}

button {
margin-left:1rem;
}

table, th, td {
  border:1px solid black;
  line-height: 2;
}

tr:nth-child(even) {
  background-color: lightgray;
  }

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

#workflow-action-filter{
  width: 10rem;
  line-height: 1.5;
  text-align: center;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.error{
  background-color:rgba(255, 222, 34, 0.5)
}
</style>
