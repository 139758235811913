<template>
  <div class="workflows" v-show="!stepError">
    <h1>Workflow Steps</h1>
    <div id="step-update-container">
      <div v-if="this.workflowStepActions===undefined || this.workflowStepActions.length===0 || this.stepsToManage.length===0" id="workflow-step-select" class="empty-steps-space">  
      </div>
      <div v-if="this.workflowStepActions!==undefined && this.workflowStepActions.length>0 && this.stepsToManage.length!==0" id="workflow-step-select">  
       <select id="workflow-step-action-filter"
                name="workflow-step-action-filter"
                class="form-control mt-1"
                v-model="selectedWorkflowStepAction"
                @change="selectWorkflowStepAction">
          <option value="" disabled selected>Step Actions</option>    
          <option v-for="wa in workflowStepActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
        </select>
        <button type="button" class="app-button primary-button" v-on:click="updateWorkflowStepStatusAndRefresh">Go</button>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Step</th>
          <th>Unknown</th>
          <th>Pending</th>
          <th>Runnable</th>
          <th>Submitted</th>
          <th>Running</th>
          <th>Completed</th>
          <th>Failed</th>
          <th>Held</th>
          <th>State</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>  
        <tr class="blank-row">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>          
          <td class="p2020-dual-buttons">
            <button v-show="!stepsStatusLoading" type="button" class="p2020-btn-small p2020-btn-clear" v-on:click="handleClearAllSteps()">Clear</button>
            <button v-show="!stepsStatusLoading" type="button" class="p2020-btn-small p2020-btn-all" v-on:click="handleSelectAllSteps()">All</button>
          </td>
          <td></td>
          <td></td>
        </tr>      
        <tr v-for="wf, index in workflowSteps" :key="index" class="steps-table" :class="this.stepLoading(wf.step) ? 'loading-step':''">
          <td class="selectable"><router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: 'ALL', step: wf.step }}">{{ wf.step}}</router-link></td>
          <td v-if="wf.status==undefined"></td>
          <td v-if="wf.status==undefined"></td>
          <td v-if="wf.status==undefined"></td>
          <td v-if="wf.status==undefined"></td>
          <td v-if="wf.status==undefined"></td>
          <td v-if="wf.status==undefined"></td>
          <td v-if="wf.status==undefined"></td>
          <td v-if="wf.status==undefined"></td>
          <td v-if="wf.status!=undefined"><div><router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: this.unknown, step: wf.step }}">{{ wf.status.UNKNOWN !== 0? wf.status.UNKNOWN: ""}}</router-link></div>
            <div v-if="unknownJobActions!==undefined && unknownJobActions.length>0">
              <font-awesome-icon @click="toggleSelectingStepStatusOn({step: wf.step, status: this.unknown})" v-if="wf.status.UNKNOWN !== 0 && !this.selectingStepStatus" :icon="['fas', 'plus']"></font-awesome-icon>
              <font-awesome-icon @click="toggleSelectingStepStatusOff({step: wf.step, status: this.unknown})" v-if="wf.status.UNKNOWN !== 0 && this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.unknown" :icon="['fas','minus']"></font-awesome-icon>
              <select v-if="this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.unknown" id="workflow-step-action-filter" name="workflow-step-action-filter" class="form-control mt-1" v-model="selectedWorkflowJobStepAction" @change="selectWorkflowJobActionForStatus">
                <option v-for="wa in unknownJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
              </select>
              <button v-if="this.stepStatusSelected && this.selectedStep===wf.step && this.selectedStatus===this.unknown" type="button" class="app-button primary-button" v-on:click="manageWorkflowSS">Go</button>
            </div>
          </td>
          <td class="selectable" v-if="wf.status!=undefined"><div><router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: this.pending, step: wf.step }}">{{ wf.status.PENDING !== 0? wf.status.PENDING: ""}}</router-link></div>
            <div v-if="pendingJobActions!==undefined && pendingJobActions.length>0">
              <font-awesome-icon @click="toggleSelectingStepStatusOn({step: wf.step, status: this.pending})" v-if="wf.status.PENDING !== 0 && !this.selectingStepStatus" :icon="['fas','plus']"></font-awesome-icon>
              <font-awesome-icon @click="toggleSelectingStepStatusOff({step: wf.step, status: this.pending})" v-if="wf.status.PENDING !== 0 && this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.pending" :icon="['fas','minus']"></font-awesome-icon>
              <select v-if="this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.pending" id="workflow-step-action-filter" name="workflow-step-action-filter" class="form-control mt-1" v-model="selectedWorkflowJobStepAction" @change="selectWorkflowJobActionForStatus">
                <option v-for="wa in pendingJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
              </select>
              <button v-if="this.stepStatusSelected && this.selectedStep===wf.step && this.selectedStatus===this.pending" type="button" class="app-button primary-button" v-on:click="manageWorkflowSS">Go</button>
            </div>
          </td>           
          <td class="selectable" v-if="wf.status!=undefined"><div><router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: this.runnable, step: wf.step }}">{{ wf.status.RUNNABLE !==0? wf.status.RUNNABLE: ""}}</router-link></div>
            <div v-if="runnableJobActions!==undefined && runnableJobActions.length>0">
              <font-awesome-icon @click="toggleSelectingStepStatusOn({step: wf.step, status: this.runnable})" v-if="wf.status.RUNNABLE !== 0 && !this.selectingStepStatus" :icon="['fas','plus']"></font-awesome-icon>
              <font-awesome-icon @click="toggleSelectingStepStatusOff({step: wf.step, status: this.runnable})" v-if="wf.status.RUNNABLE !== 0 && this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.runnable" :icon="['fas','minus']"></font-awesome-icon>
              <transition name="fade">
                <select v-if="this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.runnable" id="workflow-step-action-filter" name="workflow-step-action-filter" class="form-control mt-1" v-model="selectedWorkflowJobStepAction" @change="selectWorkflowJobActionForStatus">
                  <option v-for="wa in runnableJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
                </select>
              </transition>
              <button v-if="this.stepStatusSelected && this.selectedStep===wf.step && this.selectedStatus===this.runnable" type="button" class="app-button primary-button" v-on:click="manageWorkflowSS">Go</button>
            </div>
          </td>
          <td class="selectable" v-if="wf.status!=undefined"><div class="left"><router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: this.submitted, step: wf.step }}">{{ wf.status.SUBMITTED!==0? wf.status.SUBMITTED: ""}}</router-link></div>
            <div v-if="submittedJobActions!==undefined && submittedJobActions.length>0">
              <font-awesome-icon @click="toggleSelectingStepStatusOn({step: wf.step, status: this.submitted})" v-if="wf.status.SUBMITTED !== 0 && !this.selectingStepStatus" :icon="['fas','plus']"></font-awesome-icon>
              <font-awesome-icon @click="toggleSelectingStepStatusOff({step: wf.step, status: this.submitted})" v-if="wf.status.SUBMITTED !== 0 && this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.pending" :icon="['fas','minus']"></font-awesome-icon>
              <select v-if="this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.submitted" id="workflow-step-action-filter" name="workflow-step-action-filter" class="form-control mt-1" v-model="selectedWorkflowJobStepAction" @change="selectWorkflowJobActionForStatus">
                <option v-for="wa in submittedJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
              </select>
              <button v-if="this.stepStatusSelected && this.selectedStep===wf.step && this.selectedStatus===this.submitted" type="button" class="app-button primary-button" v-on:click="manageWorkflowSS">Go</button>
            </div>
          </td>
          <td class="selectable" v-if="wf.status!=undefined"><div><router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: this.running, step: wf.step }}">{{ wf.status.RUNNING!==0? wf.status.RUNNING: ""}}</router-link></div>
            <div v-if="runningJobActions!==undefined && runningJobActions.length>0">
            <font-awesome-icon @click="toggleSelectingStepStatusOn({step: wf.step, status: this.running})" v-if="wf.status.RUNNING !== 0 && !this.selectingStepStatus" :icon="['fas','plus']"></font-awesome-icon>
            <font-awesome-icon @click="toggleSelectingStepStatusOff({step: wf.step, status: this.running})" v-if="wf.status.RUNNING !== 0 && this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.running" :icon="['fas','minus']"></font-awesome-icon>
              <select v-if="this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.running" id="workflow-step-action-filter" name="workflow-step-action-filter" class="form-control mt-1" v-model="selectedWorkflowJobStepAction" @change="selectWorkflowJobActionForStatus">
                <option v-for="wa in runningJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
              </select>
              <button v-if="this.stepStatusSelected && this.selectedStep===wf.step && this.selectedStatus===this.running" type="button" class="app-button primary-button" v-on:click="manageWorkflowSS">Go</button>
            </div>
          </td>
          <td class="selectable" v-if="wf.status!=undefined"><div><router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: this.completed, step: wf.step }}">{{ wf.status.COMPLETED!==0? wf.status.COMPLETED: ""}}</router-link></div>
            <div v-if="completedJobActions!==undefined && completedJobActions.length>0">
              <font-awesome-icon @click="toggleSelectingStepStatusOn({step: wf.step, status: this.completed})" v-if="wf.status.COMPLETED !== 0 && !this.selectingStepStatus" :icon="['fas','plus']"></font-awesome-icon>
              <font-awesome-icon @click="toggleSelectingStepStatusOff({step: wf.step, status: this.completed})" v-if="wf.status.COMPLETED !== 0 && this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.completed" :icon="['fas','minus']"></font-awesome-icon>
              <transition name="fade">
                <select v-if="this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.completed" id="workflow-step-action-filter" name="workflow-step-action-filter" class="form-control mt-1" v-model="selectedWorkflowJobStepAction" @change="selectWorkflowJobActionForStatus">
                  <option v-for="wa in completedJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
                </select>
              </transition>
              <button v-if="this.stepStatusSelected && this.selectedStep===wf.step && this.selectedStatus===this.completed" type="button" class="app-button primary-button" v-on:click="manageWorkflowSS">Go</button>
            </div>
          </td>
          <td class="selectable" v-if="wf.status!=undefined"><div><router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: this.failed, step: wf.step }}">{{ wf.status.FAILED!==0? wf.status.FAILED: ""}}</router-link></div>
            <div v-if="failedJobActions!==undefined && failedJobActions.length>0">
            <font-awesome-icon @click="toggleSelectingStepStatusOn({step: wf.step, status: this.failed})" v-if="wf.status.FAILED !== 0 && !this.selectingStepStatus" :icon="['fas','plus']"></font-awesome-icon>
            <font-awesome-icon @click="toggleSelectingStepStatusOff({step: wf.step, status: this.failed})" v-if="wf.status.FAILED !== 0 && this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.failed" :icon="['fas','minus']"></font-awesome-icon>
              <select v-if="this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.failed" id="workflow-step-action-filter" name="workflow-step-action-filter" class="form-control mt-1" v-model="selectedWorkflowJobStepAction" @change="selectWorkflowJobActionForStatus">
                <option v-for="wa in failedJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
              </select>
              <button v-if="this.stepStatusSelected && this.selectedStep===wf.step && this.selectedStatus===this.failed" type="button" class="app-button primary-button" v-on:click="manageWorkflowSS">Go</button>
            </div>
          </td>
          <td class="selectable" v-if="wf.status!=undefined">
            <div v-if="heldJobActions!==undefined && heldJobActions.length>0">
              <router-link target="_blank" :to="{ name: 'Jobs', params: {projectName: this.projectName, workflowId: this.workflowId, status: this.held, step: wf.step }}">{{ wf.status.HELD!==0? wf.status.HELD: ""}}</router-link>
            </div>
            <font-awesome-icon @click="toggleSelectingStepStatusOn({step: wf.step, status: this.held})" v-if="wf.status.HELD !== 0 && !this.selectingStepStatus" :icon="['fas','plus']"></font-awesome-icon>
            <font-awesome-icon @click="toggleSelectingStepStatusOff({step: wf.step, status: this.held})" v-if="wf.status.HELD !== 0 && this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.held" :icon="['fas','minus']"></font-awesome-icon>
              <select v-if="this.selectingStepStatus && this.selectedStep===wf.step && this.selectedStatus===this.held" id="workflow-step-action-filter" name="workflow-step-action-filter" class="form-control mt-1" v-model="selectedWorkflowJobStepAction" @change="selectWorkflowJobActionForStatus">
                <option v-for="wa in heldJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
              </select>
              <button v-if="this.selectedStep===wf.step && this.selectedStatus===this.held" type="button" class="app-button primary-button" v-on:click="manageWorkflowSS">Go</button>
          </td>
          <td class="state-initialising" v-if="wf.no_status_record > 0 && wf.not_in_step_file === 0">Initialising</td>
          <td class="state-error" v-if="wf.not_in_step_file > 0">Status Mismatch</td>
          <td class="state-success" v-if="wf.no_status_record===0&&wf.not_in_step_file===0"><font-awesome-icon :icon="['fa', 'check']"></font-awesome-icon></td>
          <td><input type="checkbox" @click="handleManageStep(wf)" v-model="checkboxes[index]"/></td>
          <td v-if="!this.stepLoading(wf.step)" id="refresh-step" @click="refreshRow({project:  this.projectName, workflowId: this.workflowId, step: wf.step})"><img style="width:1.5rem;height:1.5rem;" :src=arrowsRotateIcon title="refresh data"/></td>
          <td v-if="this.stepLoading(wf.step)"><img :src=spinner title="refresh data"/></td>
          <td class="step-config-link" title="Step Configuration"><router-link target="_blank" :to="{ name: 'StepConfig', params: {project: this.projectName, workflow_id: this.workflowId, step: wf.step, workflowStatus: this.workflowStatus }}"><font-awesome-icon :icon="['fas', 'cog']"/></router-link></td>
        </tr>
      </tbody>
      <font-awesome-icon v-show="stepsStatusLoading" :icon="['fas', 'spinner']" class="loading-component"/>
    </table>
    <ConfirmModal v-if="confirmingChoice" @confirm="handleConfirm" :message="this.warningMessage" :level="this.level"/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Collapser from '../Generics/Collapser.vue'
import Notification from '../Generics/Notification.vue'
import ConfirmModal from '../Generics/ConfirmModal.vue'

export default {
  name: 'WorkflowSteps',
  components: {
    Collapser,
    Notification,
    ConfirmModal
  },
  props: {
    workflowId: String,
    projectName: String,
    selectedWorkflowJobStepAction: String,
    selectedWorkflowStepAction: String,
  },
  computed: {
    ...mapGetters({
      workflowSteps: 'getWorkflowStepsStatus', 
      workflowStatus: 'getWorkflowStatus',
      stepsLoading: 'stepsLoading',
      stepsStatusLoading: 'stepsStatusLoading',
      stepError: 'stepError',
      workflowStepActions: 'getWorkflowStepActions',
      selectedWorkflowJobStepAction: 'getSelectedJobStepAction',
      selectedWorkflowStepAction: 'getSelectedStepAction',
      unknownJobActions: 'getUnknownJobActions',
      runnableJobActions: 'getRunnableJobActions',
      submittedJobActions: 'getSubmittedJobActions',
      pendingJobActions: 'getPendingJobActions',
      runningJobActions: 'getRunningJobActions',
      completedJobActions: 'getCompletedJobActions',
      failedJobActions: 'getFailedJobActions',
      heldJobActions: 'getHeldJobActions',
    }),
    confirmingChoice(){
      return this.confirming
    },
  },
  data(){
    return{
      checkboxes: [],
      hide: false,
      completed: "COMPLETED",
      failed: "FAILED",
      held: "HELD",
      pending: "PENDING",
      runnable: "RUNNABLE",
      running: "RUNNING",
      submitted: "SUBMITTED",
      unknown: "UNKNOWN",
      level: "step",
      stepsToManage: [],
      selectingStepStatus: false,
      stepStatusSelected: false,
      selectedStep: "",
      selectedStatus: "",
      stepToRefresh: "",
      arrowsRotateIcon: require('../../assets/arrows-rotate-solid.svg'),
      spinner: require('../../assets/spinner-solid.svg'),
      confirming: false,
      warningMessage: '',
      choice: false,
      level: "",
    }
  },
  methods: {
    ...mapActions(
      ['loadWorkflowSteps', 
      'loadWorkflowStepsStatus', 

      'updateWorkflowStepStatus',
      'updateWorkflowJobStepStatus',
      'updateWorkflowJobStatus',

      'selectWorkflowJobAction',
      'selectWorkflowStepAction',
      'selectWorkflowJobStepAction',

      'loadWorkflowStepActions',
      'clearWorkflowStepActions',
      'loadStepStatusTableJobActions',

      'clearWorkflowJobAction',
      'clearWorkflowStepAction', 

      'refreshStepData']),
    toggleElement(){
      this.hide = !this.hide
    },
    handleManageStep(step){
      let stepsToManageIncludesStep = this.stepsToManage.includes(step)
      if(!stepsToManageIncludesStep){
        this.stepsToManage.push(step)
      }
      else{
        this.stepsToManage = this.stepsToManage.filter(s => s !== step)
      }
      this.loadWorkflowStepActions(this.stepsToManage)
    },
    handleSelectAllSteps(){
      this.stepsToManage = []
      this.checkboxes = []
      for(let i = 0; i < this.workflowSteps.length; i++){
          this.checkboxes[i] = true
          this.stepsToManage.push(this.workflowSteps[i])
      }
      this.loadWorkflowStepActions(this.stepsToManage)
    },
    handleClearAllSteps(){
      if(this.stepsToManage.length > 0 && this.checkboxes.length > 0){
        this.stepsToManage = []
        this.checkboxes = []
      }
      this.clearWorkflowStepActions()
    },
    async updateWorkflowStepStatusAndRefresh(){
      if(this.selectedWorkflowStepAction==="remove"){
        this.level="step"
        this.warningMessage='WARNING: the remove command WILL DELETE DATA created by each selected job. Please confirm or cancel action'
        this.confirming=true
      }
      else if(this.selectedWorkflowStepAction===" force_run"){
        this.level="step"
        this.warningMessage='WARNING: Each selected job WILL RUN WITH SOME INPUT DATA MISSING. Please confirm or cancel action'
        this.confirming=true
      }
      else{
        let stepArgs = {"project":  this.projectName, "workflow_id": this.workflowId, "filters": {"steps": this.stepsToManage.map(s => s.step)}}
        await this.updateWorkflowStepStatus(stepArgs);
        this.clearWorkflowStepsStatus()
      }
    },
    toggleSelectingStepStatusOn(wf){
      // bring up the selection for status commands and go button
      this.selectedStep=wf.step
      this.selectedStatus = wf.status
      this.selectingStepStatus = true
    },
    toggleSelectingStepStatusOff(){
      // close status command selection and hide go button 
      this.selectedStep=""
      this.selectedStatus=""
      this.selectingStepStatus = false
    },
    async refreshRow(stepRow){
      this.stepToRefresh = stepRow.step
      await this.refreshStepData(stepRow)
      this.stepToRefresh = ""
      this.clearWorkflowStepsStatus()
    },
    stepLoading(step){
      return step===this.stepToRefresh
    },
    selectWorkflowJobActionForStatus(e){
      this.stepStatusSelected = true
      this.selectWorkflowJobStepAction(e)
    },
    async manageWorkflowSS(){
      if(this.selectedWorkflowJobStepAction==="remove"){
        this.level="jobStep"
        this.warningMessage='WARNING: the remove command WILL DELETE DATA created by each selected job. Please confirm or cancel action'
        this.confirming=true
      }
      else if(this.selectedWorkflowJobStepAction==="force_run"){
        this.level="jobStep"
        this.warningMessage='WARNING: Each selected job WILL RUN WITH SOME INPUT DATA MISSING. Please confirm or cancel action'
        this.confirming=true
      }
      else{
        let stepArgs = {"project":  this.projectName, "workflow_id": this.workflowId, "filters": {"steps": [this.selectedStep], "status": [this.selectedStatus], "level": this.level}}
        await this.updateWorkflowJobStepStatus(stepArgs);
        await this.refreshRow({project:  this.projectName, workflowId: this.workflowId, step: this.selectedStep})
        this.clearWorkflowStepsStatus()
      }
    },
    clearWorkflowStepsStatus(){
      this.selectedStep = "",
      this.selectedStatus = ""
      this.selectingStepStatus = false
    },
    async handleConfirm(result){
      if(result.response){
        if(this.level==="jobStep"){
        let stepArgs = {"project":  this.projectName, "workflow_id": this.workflowId, "filters": {"steps": [this.selectedStep], "status": [this.selectedStatus], "level": this.level}}
        await this.updateWorkflowJobStepStatus(stepArgs);
        await this.refreshRow({project:  this.projectName, workflowId: this.workflowId, step: this.selectedStep})
        this.clearWorkflowStepsStatus()
      }
      else if(this.level==="step"){
        let stepArgs = {"project":  this.projectName, "workflow_id": this.workflowId, "filters": {"steps": this.stepsToManage.map(s => s.step)}}
        await this.updateWorkflowStepStatus(stepArgs);
        this.clearWorkflowStepsStatus()
        }
      }
      this.confirming=false
    }
  },
  async mounted () {
    let args = {workflowId: this.workflowId, projectName: this.projectName}
    await this.loadWorkflowSteps(args)
    await this.loadWorkflowStepsStatus(args)
    await this.loadStepStatusTableJobActions()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#refresh-step{
  cursor: pointer;
  width:1rem;
}

.loading-step{
  background:rgb(190, 190, 190)
}
.state-error{
  border: 0.2rem solid red;
}

.state-initialising{
  border:0.2rem solid orange;
}

.state-success{
  border: 0.2rem solid green;
  color: green;
}

svg{
  cursor: pointer;
}

td {
  height: 3rem;
}

.selectable {
  width:8rem;
}

.selectable svg{
  align-content: left;
  padding:0.25rem;
  border-radius: 1rem;
  background-color: black;
  color:white
}

.selectable svg:hover{
  background-color: rgba(0, 0, 0, 0.333);
}

.selectable svg:active{
  background-color: rgba(0, 0, 0, 0.666);;
}

.step-config-link{
  width:1rem;
}

.step-config-link a{
  color:black;
}

td:hover{
  background-color: rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
}

#step-update-container{
  display: flex;
  justify-content: center;

}

#step-update-button{
  margin:1rem auto;
}

#workflow-step-action-filter{
  width: 10rem;
  line-height: 1.5;
  text-align: center;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

#workflow-step-select{
  justify-content: center;
  margin: 1rem auto;
  display:flex;
}

#workflow-step-select button{
  margin-left: 1rem;
}

.steps-table a{
  cursor: pointer;
}

.loading-component {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: 25rem;
    width: 2rem;
    height: 2rem;
}

.empty-steps-space{
  height:2rem;
}

</style>
